<template>
    <el-dialog title="扫码记录" append-to-body :close-on-click-modal="false" :visible="visible" width="1000px"
        :before-close="closeDialog">
        <commonTable :tableData="tableData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
            :currentPage="pageNum" :loading="loading" :total="total">
            <template v-slot:table>
                <el-table-column prop="handle" label="标识" align="center"/>
                <el-table-column prop="createTime" align="center" label="扫码时间" />
                <el-table-column prop="avatarUrl" align="center" label="头像">
                    <template slot-scope="scope">
                        <img :src="scope.row.avatarUrl" class="avatar" />
                    </template>
                </el-table-column>
                <el-table-column prop="nickeName" align="center" label="昵称" />
            </template>
        </commonTable>
    </el-dialog>
</template>
<script>
import commonTable from "@/components/common/commonTable";
import { fetchRytScanLogList } from '@/api/ryt.js'
export default {
    components: {
        commonTable,
    },
    data() {
        return {
            visible: false,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            pageId: '',
            loading: false,
            handle: ''
        }
    },
    created() {
        // this.getTableData()
    },
    methods: {
        handleSizeChange(num) {
            this.pageSize = num;
            this.pageNum = 1;
            this.getTableData();
        },
        handleCurrentChange(num) {
            this.pageNum = num;
            this.getTableData();
        },

        openDialog(handle) {
            console.log(handle)
            if (handle) {
                this.handle = handle
                this.getTableData()
            }
        },
        getTableData() {
            let params = {
                handle: this.handle,
                pageNum: this.pageNum,
                pageSize: this.pageSize
            }
            fetchRytScanLogList(params).then(res => {
                this.visible = true
                this.tableData = res.list
                this.total = res.total
            })
        },
        closeDialog() {
            this.visible = false
        },
        pageSizeChange(val) {
            this.pageSize = val
            this.getTableData()
        },
        pageChange(val) {
            this.pageNum = val
            this.getTableData()
        }
    }
}
</script>
<style scoped>
.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
</style>
