<template>
  <div>
    <div style="width: 70mm;height: 35mm;">

      <div style="padding-left: 2mm;padding-top: 2mm">
<!--        <div style="float: left;font-size: 3mm;width: 5mm;line-height:5mm;text-align: center;">国家标识</div>-->
        <vue-qr
            :size="1000"
            :margin="0"
            :auto-color="true"
            :dot-scale="1"
            :text="`https://api.86122m.com/zym?zym=${handle}`"
            class="codeImage"
            style="width: 24mm;height: 24mm;float: left"
        />
      </div>
      <div>
        <div style="font-weight: bold;text-align: center;line-height: 6mm;font-size: 4mm;color: black;">任意贴</div>
        <div style="font-size: 3mm;line-height:3mm;text-align: center;">国家标识 专利防伪</div>
        <barcode  :options="barcode_option" :value="handle" style="width: 42mm;margin-left: 1mm" tag="img"></barcode>
        <div style="text-align: center;font-size: 3mm;line-height: 3mm">{{handle}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Barcode from "@xkeshi/vue-barcode";
import VueQr from 'vue-qr'
export default {
  directives: {
    print
  },
  data(){
    return {
      barcode_option: {
        displayValue: false
      },
    }
  },
  components:{Barcode,VueQr},
  props:{
    handle:{
      type:String,
      default: '86.122.2/123'
    }
  },
  methods:{

  }
}
</script>
