<template>
    <el-dialog title="巡检员" append-to-body :close-on-click-modal="false" :visible="visible" width="1000px" :before-close="closeDialog">
      <el-table ref="table" :data="data">
        <el-table-column prop="avatarUrl" label="头像" align="center">
         <template slot-scope="scope">
          <img :src="scope.row.avatarUrl" class="avatar"/>
          </template>
        </el-table-column>
        <el-table-column prop="nickeName" label="昵称" align="center"/>
        <el-table-column prop="phone" label="手机号" align="center"/>
        <el-table-column prop="phone" label="状态" align="center">
            <template slot-scope="scope">
              {{scope.row.auditStatus | formatStatus}}
            </template>
        </el-table-column>
      </el-table>
      <el-pagination
      :page-size="pageSize"
      :total="total"
      :current-page="pageNum"
      style="margin-top: 8px;"
      @size-change="pageSizeChange($event)"
      @current-change="pageChange($event)"
    />
    </el-dialog>
</template>
<script>
import { fetchRytCheckUserList } from '@/api/ryt.js'
 export default {
     data() {
         return {
             visible: false,
             data: [],
             pageNum: 1,
             pageSize: 10,
             total: 0,
             pageId: ''
         }
     },
   filters:{
     formatStatus(status){
       if (status == 0){
         return '待审核'
       }else if (status == 1){
         return '审核通过'
       }else if (status == 2){
         return '审核驳回'
       }
     }
   },
     methods: {
        openDialog(pageId) {
            if(pageId) {
                this.pageId= pageId
                this.getData()
            }
        },
        getData(){
            let params = {
                pageId: this.pageId,
                pageNum: this.pageNum,
                pageSize: this.pageSize
            }
          fetchRytCheckUserList(params).then(res => {
            console.log(res)
                this.visible=true
                this.data= res.list
                this.total=res.total
            })
        },
        closeDialog() {
            this.visible = false
        },
        pageSizeChange(val){
          this.pageSize = val
          this.getData()
        },
        pageChange(val) {
            this.pageNum=val
            this.getData()
        }

     }
 }
</script>
<style scoped>
.myImg{
    width: 80px;
    height: 80px;
}
.avatar{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
</style>
