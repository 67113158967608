<template>
	<div class="view pa24">
		<div>
			<el-form label-width="100" :inline="true">
				<el-form-item label="标识">
					<el-input v-model="handle"></el-input>
				</el-form-item>
				<el-form-item label="标题">
					<el-input v-model="title"></el-input>
				</el-form-item>
				<el-button size="medium" type="primary" @click="handleSearchData">搜索</el-button>
				<el-button size="medium" @click="handleReset">重置</el-button>

			</el-form>
		</div>
		<div>
			<el-button type="primary" size="medium" @click="showPrintAll" style="margin-bottom: 20px;">批量打印</el-button>
			<el-button size="medium" type="primary" @click="handleOpenUserDialog">更换管理员</el-button>
		</div>
		<commonTable keyId="id" :tableData="tableData" @handleSizeChange="handleSizeChange"
			@handleCurrentChange="handleCurrentChange" :currentPage="pageNum" :loading="loading" :total="total"
			@handleSelectionChange="handleSelectionChange">
			<template v-slot:table>
				<el-table-column type="selection" :selectable="checkboxSelect" width="55" :reserve-selection="true">
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" align="center" prop="title" label="标题" />
				<el-table-column prop="title" align="center" label="二维码" width="150">
					<template slot-scope="scope">
						<vue-qr :size="200" :margin="20" :auto-color="true" :dot-scale="1"
							:text="`https://api.86122m.com/ryt?ryt=${scope.row.handle}`" class="tableCodeImg codeImage" />
					</template>
				</el-table-column>
				<el-table-column align="center" :show-overflow-tooltip="true" prop="handle" label="标识" />
				<el-table-column align="center" prop="password" label="激活码" />
				<el-table-column align="center" :show-overflow-tooltip="true" prop="activeTime" label="激活时间" />
				<el-table-column align="center" prop="nickName" label="管理员" />
				<!--            <el-table-column prop="status" label="状态">-->
				<!--                <template slot-scope="scope">-->
				<!--                   <template v-if="rytTypeCode === 2">-->
				<!--                      <span class="green" v-if="scope.row.status == 1">-->
				<!--                        可借-->
				<!--                      </span>-->
				<!--                      <span class="blue" v-else-if="scope.row.status == 2">-->
				<!--                        不可借-->
				<!--                        </span>-->
				<!--                      <span class="grey" v-else>-->
				<!--                        未激活-->
				<!--                      </span>-->
				<!--                   </template>-->
				<!--                   <template v-else>-->
				<!--                      <span class="green" v-if="scope.row.status == 1">-->
				<!--                        已激活-->
				<!--                      </span>-->
				<!--                      <span class="grey" v-else>-->
				<!--                        未激活-->
				<!--                      </span>-->
				<!--                   </template>-->
				<!--                </template>-->
				<!--            </el-table-column>-->
				<!--图书start-->
				<!--              <template v-if="rytTypeCode === 2">-->
				<!--                <el-table-column prop="commentNum" label="评论数量">-->
				<!--                  <template slot-scope="scope">-->
				<!--                    <span class="buttonTxt" @click="handleCheckComment(scope.row)">{{scope.row.commentNum}}</span>-->
				<!--                  </template>-->
				<!--                </el-table-column>-->
				<!--                <el-table-column prop="thumbNum" label="点赞数量">-->
				<!--                  <template slot-scope="scope">-->
				<!--                    <span class="buttonTxt" @click="handleCheckZan(scope.row)">{{scope.row.thumbNum}}</span>-->
				<!--                  </template>-->
				<!--                </el-table-column>-->
				<!--              </template>-->
				<!--图书end-->

				<el-table-column align="center" :show-overflow-tooltip="true" prop="createTime" label="创建时间" />
				<el-table-column prop="" align="center" label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="getQrCodeImage(scope.row, scope.$index)">下载二维码</el-button>
						<el-button type="text" @click="showPrint(scope.row)">打印</el-button>
						<template>
							<el-button type="text" @click="handleCheckLog(scope.row)">操作记录</el-button>
							<el-button type="text" @click="exportHandleLog(scope.row)">导出操作记录</el-button>
							<el-button type="text" @click.stop="scanLog(scope.row)">扫码记录</el-button>
							<el-button v-if="scope.row.needAudit" type="text" @click="checkUserList(scope.row)">巡检员</el-button>
						</template>
					</template>
				</el-table-column>
			</template>
		</commonTable>

		<UserDialog ref="userDialog" @setUser="handleSetUser"></UserDialog>
		<!-- 扫码记录 -->
		<ScanLogDialog ref="scanLogDialog"></ScanLogDialog>
		<!-- 操作记录 -->
		<logDialog ref="logDialog"></logDialog>
		<!--批量打印弹窗-->
		<el-dialog title="批量打印" :visible.sync="printAllVisible" width="415px" append-to-body>
			<el-select v-model="printType" default-first-option placeholder="请选择打印规格">
				<el-option v-for="item in printOptions" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<el-button v-print="'#printAll'">打印</el-button>
			<div ref="printAll" class="printAllWrap" id="printAll" v-if="printType === '1'">
				<div class="printItem" style="width: 375px;height: 525px;" v-for="item in printAllData" :key="item.handle">
					<div style="text-align: center;padding:4px;padding-top: 40px;font-size: 20px;font-weight: bold">任意贴
					</div>
					<div style="overflow:hidden;">
						<div style="float: left;font-size: 16px;width: 16px;text-align: center;margin: 60px 21px 0 50px">
							国家标识</div>
						<vue-qr :size="600" :margin="0" :auto-color="true" :dot-scale="1"
							:text="`https://api.86122m.com/zym?zym=${item.handle}`" class="codeImage"
							style="width: 200px;height: 200px;margin-top: 10px;float: left" />
						<div style="float: left;font-size: 16px;width: 16px;margin: 60px 0px 0 21px">{{ rytTypeCode == 2 ?
                            '图书' : '设备' }}案例</div>
					</div>
					<div style="text-align: center;margin-top: 4px;padding:10px">{{ item.handle }}</div>
					<barcode :options="barcode_option" :value="item.handle"
						style="width: 340px;display:block;margin: 0 auto;margin-top: 30px" tag="img"></barcode>
					<div style="text-align: center;margin-top: 4px;">{{ item.handle }}</div>
				</div>
			</div>
			<div v-if="printType === '2'">
				<div id="printAll">
					<div v-for="item in printAllData" :key="item.handle">
						<print3070 :handle="item.handle" />
					</div>
				</div>
			</div>
		</el-dialog>
		<!--单个打印-->
		<el-dialog :title="title" :visible.sync="printVisible" width="415px" append-to-body>
			<el-select v-model="printType" default-first-option placeholder="请选择打印规格">
				<el-option v-for="item in printOptions" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<div ref="print" id="printMe" style="width: 375px;height: 525px;" v-if="printType === '1'">
				<div style="text-align: center;padding:4px;padding-top: 40px;font-size: 20px;font-weight: bold">任意贴</div>
				<div style="overflow:hidden;display:flex; justify-content: center;align-items:center;">
					<div style="font-size: 16px;width: 16px;text-align: center;">国家标识
					</div>
					<vue-qr :size="600" :margin="0" :auto-color="true" :dot-scale="1"
						:text="`https://api.86122m.com/zym?zym=${printHandle}`" class="codeImage"
						style="width: 100px;height: 100px;margin: 10px 21px;" />
					<div style="font-size: 16px;width: 16px;">专利防伪</div>
				</div>

				<div style="text-align: center;margin-top: 4px;padding:10px">{{ printHandle }}</div>
				<barcode :options="barcode_option" :value="printHandle"
					style="width: 340px;display:block;margin: 0 auto;margin-top: 30px" tag="img"></barcode>
				<div style="text-align: center;margin-top: 4px;">{{ printHandle }}</div>
			</div>
			<div ref="print" id="printMe" v-if="printType === '2'">
				<print3070 :handle="printHandle" />
			</div>

			<el-button v-print="'#printMe'">打印</el-button>
		</el-dialog>

		<!--      巡检员-->
		<zdy-check-user-dialog ref="checkUserDialog"></zdy-check-user-dialog>
	</div>
</template>
<script>
	import commonTable from "@/components/common/commonTable";
	import VueQr from 'vue-qr'
	import print from 'vue-print-nb'
	import {
		fetchRythtPage,
		zdyPrintAllList,
		fetchChangeAdmin
	} from '@/api/ryt.js'
	import UserDialog from './components/UserDialog.vue'
	import ScanLogDialog from "./components/scanLog.vue";
	import logDialog from './components/logDialog.vue'
	import barcode from "@xkeshi/vue-barcode";
	import Print3070 from "./components/Print/Print3070.vue";
	import ZdyCheckUserDialog from "./components/zdyCheckUserDialog.vue";
	import {
		exportLog
	} from '@/api/ryt.js'
	import {
		export_json_to_excel
	} from "../../excel/Export2Excel";
	export default {
		name: "handleIndex",
		components: {
			commonTable,
			VueQr,
			UserDialog,
			ScanLogDialog,
			logDialog,
			barcode,
			Print3070,
			ZdyCheckUserDialog
		},
		directives: {
			print
		},
		data() {
			return {
				pageNum: 1,
				pageSize: 10,
				total: 0,
				tableData: [],
				title: '',
				handle: '',
				batchId: '',
				status: '',
				loading: false,
				multipleSelection: {},
				manageUserIds: [],
				printAllData: {},
				printAllVisible: false,
				printHandle: '',
				printVisible: false,
				printOptions: [{
					value: '1',
					label: '100*70'
				}, {
					value: '2',
					label: '30*70'
				}],
				printType: '1',
				barcode_option: {
					displayValue: false
				},
				rytTypeCode: '',
			}
		},
		created() {

		},
		mounted() {
			if (this.$route.query.batchId) {
				this.batchId = this.$route.query.batchId
			}
			this.getTableData()
		},
		methods: {
			getRowId(row) {
				return row.id; // row-key绑定的必须为唯一值
			},
			exportHandleLog(row) {
				let params = {
					pageId: row.id
				}
				//导出操作记录
				exportLog(params).then(res => {
					console.log(res)
					if (res) {
						const {
							export_json_to_excel
						} = require('../../excel/Export2Excel.js');
						console.log(export_json_to_excel);
						const tHeader = ['标题', '标识', '操作', '操作人', '时间']; //
						// 上面设置Excel的表格第一行的标题
						const filterVal = ['title', 'handle', 'state', 'nickeName', 'createTime']; //
						// 上面的index、nickName、name是tableData里对象的属性
						const list = res.map(item => {
							item.title = row.title
							item.handle = row.handle
							//   item.content= this.getContent(item.content)
							return item
						}); //把data里的tableData存到list
						const data = this.formatJson(filterVal, list);
						var titName = row.title + "操作数据_" + new Date().getTime()
						this.exloading = false
						export_json_to_excel(tHeader, data, titName);
					}
				})
			},
			formatJson(filterVal, jsonData) {
				return jsonData.map(v => filterVal.map((j) => {
					return v[j]
				}))
			},
			getContent(str) {
				let content = JSON.parse(str)
				let result = "";
				content.forEach(item => {
					result += item.title + ":" + item.value
					result += "/"
				})
				return result;
			},
			checkboxSelect(row, rowIndex) {
				//绑定参数表格禁用
				return row.activeTime //当满足什么条件时启用
			},
			handleCheckLog(data) {
				this.$nextTick(() => {
					this.$refs.logDialog.openDialog(data.id)
				})

			},
			scanLog(data) {
				this.$refs.scanLogDialog.openDialog(data.handle)
			},
			getTableData() {
				let params = {
					title: this.title,
					handle: this.handle,
					batchId: this.batchId,
					status: this.status,
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}
				fetchRythtPage(params).then(res => {
					this.tableData = res.list
					this.total = res.total
				})
			},
			handleSizeChange(num) {
				this.pageSize = num;
				this.pageNum = 1;
				this.getTableData();
			},
			handleCurrentChange(num) {
				this.pageNum = num;
				this.getTableData();
			},
			handleOpenUserDialog() {
				// todo 加判断 没有选标识id，提示勾选再弹窗
				if (!this.multipleSelection.length) {
					this.$message({
						message: '请至少选择一个已激活的标识码',
						type: 'warning'
					});
					return
				}
				// if (this.multipleSelection.length > 1) {
				// 	this.$message({
				// 		message: '只能选择一个已激活的标识码',
				// 		type: 'warning'
				// 	});
				// 	return
				// }
				this.$refs.userDialog.openDialog(this.multipleSelection[0].adminIds)
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleSetUser(userIds) {
				console.log(userIds)
				this.manageUserIds = userIds
				if (!this.manageUserIds || this.manageUserIds.length <= 0) {
					this.$message('请选择管理员');
					return
				}
				let arr = []
				this.multipleSelection.forEach(item => {
					arr.push(item.id)
				})
				let params = {
					userIds: this.manageUserIds,
					ids: arr,
					type: 1
				}
				fetchChangeAdmin(params).then(res => {
					this.$message({
						message: '操作成功',
						type: 'success'
					});
					this.getTableData()
				})
			},
			getQrCodeImage(data, index) {
				var src = document.getElementsByClassName('codeImage')[index].getAttribute('src')
				var a = document.createElement('a');
				a.href = src
				a.download = data.handle + '.png';
				a.click();
			},
			showPrint(row) {
				this.printHandle = row.handle
				this.printVisible = true
			},
			showPrintAll(row) {
				// 根据批次号，获取不分页数据
				let params = {
					batchId: this.batchId
				}
				zdyPrintAllList(params).then(res => {
					this.printAllData = res
					this.printAllVisible = true
				})
			},
			handleSearchData() {
				this.pageNum = 1
				this.getTableData()
			},
			handleReset() {
				this.title = ''
				this.handle = ''
				this.status = ''
				this.pageNum = 1
				this.getTableData()
			},
			checkUserList(data) {
				this.$refs.checkUserDialog.openDialog(data.id)
			}
		}
	}
</script>
<style scoped>
	.avatar {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	.tableCodeImg {
		width: 100px;
		height: 100px;
	}
</style>