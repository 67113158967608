<template>
  <el-dialog title="操作记录" append-to-body :close-on-click-modal="false" :visible="visible" width="1000px"
    :before-close="closeDialog">
    <commonTable :tableData="tableData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
      :currentPage="pageNum" :loading="loading" :total="total">
      <template v-slot:table>
        <el-table-column prop="state" width="100" label="操作" align="center">
          <template slot-scope="scope">
            {{ scope.row.state }}
          </template>
        </el-table-column>
      <el-table-column prop="createTime" align="center" label="内容" width="300px">
        <template slot-scope="scope">
          <div v-for="(item,index) in scope.row.content" :key="index" style="text-aligin: left">
            <div v-if="item.type === 1" class="cItem">
              {{item.title}}: {{item.value}}
            </div>
            <div v-if="item.type === 2" style="">
              <div style="text-aligin: left;display:flex;">{{item.title}}:</div>
              <div style="text-aligin: left; display:flex;">
                <el-image :fit="contain" :src="baseImg+imgItem.src" :preview-src-list="[baseImg+imgItem.src]" v-for="imgItem in item.imgList" :key="imgItem.src" class="conImg"></el-image>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
        <el-table-column prop="stateName" align="center" label="状态" />
        <el-table-column prop="avatarUrl" align="center" label="头像">
          <template slot-scope="scope">
            <img :src="scope.row.avatarUrl" class="avatar" />
          </template>
        </el-table-column>
        <el-table-column prop="nickeName" align="center" label="昵称" />
      <el-table-column prop="createTime" align="center" label="时间" />
      
    </template>
    </commonTable>
  </el-dialog>
</template>
<script>
import { fetchRytLogList } from '@/api/ryt.js'
import commonTable from "@/components/common/commonTable";
export default {
  components: {
        commonTable,
    },
  data() {
    return {
      visible: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      pageId: '',
      loading:false,
      baseImg: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/',
    }
  },
  methods: {
    handleSizeChange(num) {
            this.pageSize = num;
            this.pageNum = 1;
            this.getData();
        },
        handleCurrentChange(num) {
            this.pageNum = num;
            this.getData();
        },
    openDialog(id) {
      if (id) {
        this.pageId = id
        this.getData()
      }
    },
    getData() {
      let params = {
        pageId: this.pageId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      fetchRytLogList(params).then(res => {
        this.visible = true
        this.tableData = res.list
        this.tableData.forEach(item=>{
          item.content = JSON.parse(item.content)
          if(item.content) {
            for(let i=0; i< item.content.length; i++) {
              if(item.content[i].imgList) {
                item.content[i].imgList = this.workList(item.content[i].imgList)
              }
            }
          }
        })
        this.total = res.total
      })
    },
    closeDialog() {
      this.visible = false
    },
    pageSizeChange(val) {
      this.pageSize = val
      this.getData()
    },
    pageChange(val) {
      this.pageNum = val
      this.getData()
    },
    workList(arr) {
      let res = []
      arr.forEach(item => {
        if(item.src) {
          res.push(item)
        }
      })
      return res
    }

  }
}
</script>
<style scoped>
.myImg {
  width: 80px;
  height: 80px;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.conImg{
  width: 50px;
}
.cItem{
  display: flex;
}
</style>
