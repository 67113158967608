<template>
	<el-dialog title="用户列表" append-to-body :close-on-click-modal="false" :visible="visible" width="800px"
		:before-close="closeDialog">
		<div>
			<el-form label-width="100" :inline="true">
				<el-form-item label="昵称">
					<el-input v-model="searchForm.nickName"></el-input>
				</el-form-item>
				<el-form-item label="手机号">
					<el-input v-model="searchForm.phone"></el-input>
				</el-form-item>
				<el-button size="medium" type="primary" @click="handleSearchData">搜索</el-button>
				<el-button size="medium" @click="handleReset">重置</el-button>

			</el-form>
		</div>


		<commonTable :ids="ids" keyId="userId" ref="commonTable" :tableData="data" @handleSizeChange="handleSizeChange"
			@handleCurrentChange="handleCurrentChange" :currentPage="pageNum" :total="total"
			@handleSelectionChange="handleSelectionChange" @getRowKey="getRowKey">
			<template v-slot:table>
				<el-table-column label width="35" :reserve-selection="true" type="selection">
				</el-table-column>
				<el-table-column prop="avatarUrl" align="center" label="头像">
					<template slot-scope="scope">
						<img :src="scope.row.avatarUrl" class="avatarImg" />
					</template>
				</el-table-column>
				<el-table-column prop="nickeName" align="center" label="昵称" />
				<el-table-column prop="phone" align="center" label="手机号" />
			</template>
		</commonTable>
		<span slot="footer" class="dialog-footer">
			<el-button @click="closeDialog">取 消</el-button>
			<el-button type="primary" @click="handleConfirmUser">确 定</el-button>
		</span>
		<!--    <el-table ref="table" :data="data" @row-click="itemRowClick">-->
		<!--      -->
		<!--    </el-table>-->
		<!--    <el-pagination-->
		<!--        :page-size="pageSize"-->
		<!--        :total="total"-->
		<!--        :current-page="pageNum"-->
		<!--        style="margin-top: 8px;"-->
		<!--        layout="total, prev, pager, next, sizes"-->
		<!--        @size-change="pageSizeChange($event)"-->
		<!--        @current-change="pageChange($event)"-->
		<!--    />-->

	</el-dialog>

</template>
<script>
	import {
		fetchCompanyUserList
	} from '@/api/ryt.js'
	import commonTable from "@/components/common/commonTable";
	export default {
		components: {
			commonTable
		},
		data() {
			return {
				allData:[],
				visible: false,
				data: [],
				pageNum: 1,
				pageSize: 10,
				total: 0,
				rytId: '',
				sourceId: [],
				searchForm: {
					nickName: null,
					phone: null
				},
				// 回显id列表
				idList: [],
				// 选中id列表
				selects: [],
				ids: []
			}
		},
		created() {},
		methods: {
			getRowKey(row) {
				return row.userId;
			},
			handleSelectionChange(val) {
				this.$forceUpdate()
				if (val && val.length > 0) {
					this.sourceId = []
				}
				val.forEach((item, index) => {
					if (!this.sourceId.includes(item.userId)) {
						this.sourceId.push(item.userId)
					}
				})
				console.log(this.sourceId)
			},
			handleSizeChange(num) {
				this.pageSize = num;
				this.pageNum = 1;
				this.getData();
			},
			handleCurrentChange(num) {
				this.pageNum = num;
				this.getData();
			},
			handleReset() {
				this.searchForm.nickName = ''
				this.searchForm.phone = ''
				this.pageNum = 1
				this.getData()
			},
			handleSearchData() {
				this.getData()
			},
			itemRowClick(row, column, event) {
				this.sourceId = row.userId
			},
			openDialog(id) {
				this.ids = id.split(',')
				this.sourceId = []
				this.handleReset()
				// this.getData()
			},
			getData() {
				let params = {
					pageNum: this.pageNum,
					pageSize: this.pageSize,
				}
				Object.assign(params, this.searchForm)
				fetchCompanyUserList(params).then(res => {
					this.data = res.list
					this.total = res.total
					this.visible = true
				})
				
			},
			closeDialog() {
				this.ids = []
				this.visible = false
				this.$refs.commonTable.setCurrent()
			},
			pageSizeChange(val) {
				this.pageSize = val
				this.getData()
			},
			pageChange(val) {
				this.pageNum = val
				this.getData()
			},
			handleConfirmUser() {
				this.$emit('setUser', this.sourceId)
				this.closeDialog()
			},
		}
	}
</script>
<style scoped>
	.myImg {
		width: 80px;
		height: 80px;
	}

	.avatarImg {
		width: 35px;
		height: 35px;
		border-radius: 50%;
		overflow: hidden;
	}
</style>