import request from '@/utils/request';
import {get} from "../utils/request";
const Qs = require('qs');
// import httpUtil from "@/utils/httpUtil";

/***创建空白标识 **/
export function fetchRytCreat(params) {
	// return httpUtil.post("/customPage", params)
	return request({
		url: '/ryt/create',
		method: 'post',
		data: params
	});
}


/***任意贴标识列表 **/
export function fetchRytList(params) {
	return request({
		url: '/ryt/list?' + Qs.stringify(params),
		method: 'get'
	});
}

/***借还记录 */
export function fetchLogList(params) {
	return request({
		url: '/ryt/rytLogList?' + Qs.stringify(params),
		method: 'get'
	});
}
/**巡检记录**/
export function fetchCheckLogList(params) {
	return request({
		url: '/ryt/checkList?' + Qs.stringify(params),
		method: 'get'
	});
}
/***读后感记录 */
export function fetchCognitionList(params) {
	return request({
		url: '/ryt/cognitionList?' + Qs.stringify(params),
		method: 'get'
	});
}

/***评论记录 */
export function fetchCommentList(params) {
	return request({
		url: '/ryt/rytCommentList?' + Qs.stringify(params),
		method: 'get'
	});
}

/***点赞记录 ***/
export function fetchZanList(params) {
	return request({
		url: '/personal/zanList?' + Qs.stringify(params),
		method: 'get'
	});
}

/***添加任意贴场景、类型 */
export function fetchRytType(params) {
	// return httpUtil.post("/customPage", params)
	return request({
		url: '/ryt/type/addOrUpdate',
		method: 'post',
		data: params
	});
}

/***任意贴类型列表 */
export function fetchRytTypeList(params) {
	return request({
		url: '/ryt/type/list?' + Qs.stringify(params),
		method: 'get'
	});
}


/***任意贴批次号列表****/
export function fetchRytCreateList(params) {
	return request({
		url: '/ryt/lotNumberList?' + Qs.stringify(params),
		method: 'get'
	});
}

/***任意贴类型，不分页 */
export function fetchAllTypes(params) {
	return request({
		url: '/ryt/type/noPage?' + Qs.stringify(params),
		method: 'get'
	});
}
/***删除任意贴类型 */
export function fetchDelType(id) {
	return request({
		url: `/ryt/type/delete/${id}`,
		method: 'delete',
	});
}

/***任意贴标识列表，不分页 */
export function fetchRytListAll(params) {
	return request({
		url: '/ryt/noPageByLotNumber?' + Qs.stringify(params),
		method: 'get'
	});
}

/***导出任意贴 */
export function fetchRytExport(params) {
	return request({
		url: '/ryt/export?' + Qs.stringify(params),
		method: 'get'
	});
}

/***top榜 */
export function fetchRytTop(params) {
	return request({
		url: '/ryt/rytScanTop?' + Qs.stringify(params),
		method: 'get'
	});
}

/***曲线图 */
// export function fetchRytTop(params) {
// 	return request({
// 		url: '/ryt/rytScanTop?' + Qs.stringify(params),
// 		method: 'get'
// 	});
// }

/**曲线图 */
export function fetchScanData(params) {
	return request({
		url: '/ryt/rytInfoLine?' + Qs.stringify(params),
		method: 'get'
	});
}
/***类型统计 **/
export function fetchTypeTJ(params) {
	return request({
		url: '/ryt/rytTypeTj?' + Qs.stringify(params),
		method: 'get'
	});
}
/***统计详情 */
export function fetchTypeDetailTJ(params) {
	return request({
		url: '/ryt/rytTjDetail?' + Qs.stringify(params),
		method: 'get'
	});
}
// top榜详情
export function fetchTypeScanTJ(params) {
	return request({
		url: '/ryt/rytInfoLogDetail?' + Qs.stringify(params),
		method: 'get'
	});
}

// 修改密码
export function fetchChangePwd(params) {
	return request({
		url: '/company/updateAdminPwd',
		method: 'post',
		data: params
	});
}

// 获取用户列表
export function fetchUserList(params) {
	return request({
		url: '/company/selectUserList?' + Qs.stringify(params),
		method: 'get'
	});
}

// 更换管理员
export function fetchChangeAdmin(params) {
	return request({
		url: '/ryt/changeAdmin',
		method: 'post',
		data: params
	});
}

//不分页模板列表
export function fetchRytModelList(params) {
	return request({
		url: '/ryt/noPage?' + Qs.stringify(params),
		method: 'get'
	});
}
//创建空白页
export function fetchCreateBatchPage(params) {
	return request({
		url: '/ryt/batchPage',
		method: 'post',
		data: params
	});
}
//批次列表
export function fetchRytPageBatchList(params) {
	return request({
		url: '/ryt/batch?' + Qs.stringify(params),
		method: 'get'
	});
}

//批次列表
export function fetchRythtPage(params) {
	return request({
		url: '/ryt/htPage?' + Qs.stringify(params),
		method: 'get'
	});
}
//模板列表
export function fetchRytModel(params) {
	return request({
		url: '/ryt/model?' + Qs.stringify(params),
		method: 'get'
	});
}

//操作记录列表
export function fetchRytLogList(params) {
	return request({
		url: '/ryt/logList?' + Qs.stringify(params),
		method: 'get'
	});
}
//自定义统计数据
export function fetchZdyTJ(params) {
	return request({
		url: '/ryt/rytStatistics?' + Qs.stringify(params),
		method: 'get'
	});
}
export function fetchZdyScanData(params) {
	return request({
		url: '/ryt/scanLine?' + Qs.stringify(params),
		method: 'get'
	});
}
export function fetchZdyTopData(params) {
	return request({
		url: '/ryt/scanTop?' + Qs.stringify(params),
		method: 'get'
	});
}

export function fetchZdyTjDetail(params) {
	return request({
		url: '/ryt/tjDetail?' + Qs.stringify(params),
		method: 'get'
	});
}

export function fetchZdyScanDetail(params) {
	return request({
		url: '/ryt/scanNumDetail?' + Qs.stringify(params),
		method: 'get'
	});
}

/***导出自定义任意贴 */
export function fetchZdyRytExport(params) {
	return request({
		url: '/ryt/zdy/export?' + Qs.stringify(params),
		method: 'get'
	});
}
/**修改任意贴备注 */
export function fetchUpdateRytRemark(params) {
	return request({
		url: '/ryt/updateRytBatch',
		method: 'post',
		data: params
	});
}

export function fetchChangeModelType(params) {
	return request({
		url: '/ryt/changeModelType',
		method: 'post',
		data: params
	});
}

export function fetchRytScanLogList(params) {
	return request({
		url: '/ryt/scanLogList?' + Qs.stringify(params),
		method: 'get'
	});
}

export function fetchRytCheckUserList(params) {
	return request({
		url: '/ryt/checkUserList?' + Qs.stringify(params),
		method: 'get'
	});
}

export function fetchRytState(params) {
	return request({
		url: '/ryt/stateStatistics?' + Qs.stringify(params),
		method: 'get'
	});
}
// export function fetchRytStateInfo(params) {
// 	return request({
// 		url: '/ryt/stateStatisticsInfo?' + Qs.stringify(params),
// 		method: 'get'
// 	});
// }
export function fetchRytStateInfo(params) {
	return request({
		url: '/ryt/pageStatistics?' + Qs.stringify(params),
		method: 'get'
	});
}
export function fetchRytStateModelList(params) {
	return request({
		url: '/ryt/stateModelList?' + Qs.stringify(params),
		method: 'get'
	});
}

export function fetchRytStateTjDetailList(params) {
	return request({
		url: '/ryt/stateStatisticsDetail?' + Qs.stringify(params),
		method: 'get'
	});
}

export function fetchUpdateZdyRytRemark(params) {
	return request({
		url: '/ryt/zdyUpdateRemark',
		method: 'post',
		data: params
	});
}


export function zdyPrintAllList(params) {
	return request({
		url: '/ryt/printAllList?' + Qs.stringify(params),
		method: 'get'
	});
}

/***分配模版回显 */
export function fetchCompanyModelInit(params) {
	return request({
		url: '/ryt/getCompanyModel?' + Qs.stringify(params),
		method: 'get',
	});
}
export function fetchCompanyModel(params) {
	return request({
		url: '/ryt/distRytModel',
		method: 'post',
		data: params
	});
}

export function fetchCompanyUserList(params) {
	return request({
		url: '/ryt/companyUserList?' + Qs.stringify(params),
		method: 'get'
	});
}

export function fetchDelModel(id) {
	return request({
		url: `/ryt/delModel/${id}`,
		method: 'delete',
	});
}
export function exportLog(params){
	return get('/ryt/exportLog',params)
}

export function getAllBtnTop(params){
	return get('/ryt/getAllBtnTop?'+Qs.stringify(params))
}

export function getBtnNumDetail(params){
	return get('/ryt/btnTopDetail?'+Qs.stringify(params))
}
export function getCompanyClass(params){
	return get('/rytModelClass/getCompanyClass',params)
}

export function rytBtnLine(params){
	return get('/ryt/rytBtnLine?' + Qs.stringify(params))
}

export function rytBtnStateLine(params){
	return get('/ryt/rytBtnStateLine?' + Qs.stringify(params))
}

export function fetchgetPage(params){
	return get('/ryt/getPage?' + Qs.stringify(params))
}

export function fetchDetailMore(params){
	return get('/ryt/getAllBtnTopMore?' + Qs.stringify(params))
}


